import React from 'react';

import './styles.css';
import logo from '../../assets/bot-icon-640.png';


export default function() {
	return (
		<div className="page">
			<div className="container">
				<img src={logo} className="not-found-logo" alt="logo" />

				<p className="slogan">
					<b>Page not found</b>
				</p>
			</div>
		</div>
	)
}