import React from 'react';

import cover from '../../assets/blockchain-640x360.png';
import './styles.css';


export default function() {
	return (
		<div className="App">
			<header className="App-header">
				<img src={cover} className="App-logo" alt="logo" />
				
				<p className="slogan">
					We Make Connect Easy
				</p>
			</header>
		</div>
	);
}