import React from 'react';

import {
  BrowserRouter,
  Link,
  Route,
  Switch
} from 'react-router-dom';

import {
	Navbar,
	Nav
} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

// import logo from '../../assets/logo.svg';
import logo from '../../assets/bot-icon-64.png';
import './styles.css';


import HomeComp from '../home';
import TOSComp from '../terms-of-use';
import PPComp from '../privacy-policy';
import NotFound from '../not-found';
import AppTermsComp from '../app-terms';


export default class extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<div className="app-page">
					<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
						<Navbar.Brand href="/">
							<img
								src={logo}
								width="30"
								height="30"
								className="d-inline-block align-top"
								alt="React Bootstrap logo"
							/>
						</Navbar.Brand>

						<Navbar.Toggle aria-controls="responsive-navbar-nav" />

						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="mr-auto">
								<Nav.Link href="/"><b>Elfwill</b></Nav.Link>
							</Nav>

							<Nav>
								<Nav.Link href="/terms-of-use">Terms of Use</Nav.Link>
								<Nav.Link href="/app-terms">App Terms</Nav.Link>
								<Nav.Link href="/privacy-policy">Privacy Policy</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Navbar>

					<Switch>
						<Route
							exact={true}
							path="/"
							render={props => (
								<HomeComp />
							)}
						/>

						<Route
							exact={true}
							path="/terms-of-use"
							render={props => (
								<TOSComp />
							)}
						/>

<Route
							exact={true}
							path="/app-terms"
							render={props => (
								<AppTermsComp />
							)}
						/>

						<Route
							exact={true}
							path="/privacy-policy"
							render={props => (
								<PPComp />
							)}
						/>

						<Route
							component={NotFound}
						/>
					</Switch>
				</div>
			</BrowserRouter>
		)
	}
};
